export default [
  {
    title: 'CSS Fundamentals - Selection',
    description: 'Learn about selecting HTML elements to style using CSS. Selecting the element or group of elements you want to style is the first step in building styles that look beautiful and consistent across all of the HTML elements that these styles are applied to. It also allows your CSS files to scale to very large projects and websites.',
    posts: [
      {
        slug: 'css-selectors',
        subsection: '1.1',
        title: 'Select HTML elements to style with the right selectors and pseudo classes'
      },
      {
        slug: 'specificity-priority',
        subsection: '1.2',
        title: 'Specifying and prioritizing CSS styles'
      }
    ]
  },
  {
    title: 'Color and Typography',
    description: 'CSS is a language that bridges art and science. To make good-looking styles that scale, they first have to look pretty. Color theory and font pairings are essential fundamentals in making your websites look pleasing and usable.',
    posts: [
      {
        slug: 'typography',
        subsection: '2.1',
        title: 'Typography and text styling'
      },
      {
        slug: 'color-application',
        subsection: '2.2',
        title: 'Color Theory and Application'
      }
    ]
  },
  {
    title: 'Spacing and the Box Model',
    description: 'Whitespace is an essential yet often overlooked part of design. If your elements are too close together, they look cluttered. If they are too far apart, users have to do much more work to find what they are looking for. Learn to strike the perfect balance for beautiful and usable web design using element spacing the right way.',
    posts: []
  },
  {
    title: 'Element specific Formatting',
    description: 'Learn about different ways to format specific elements in HTML. Images should be formatted differently from tables and yet there are best practices in formatting each of these.',
    posts: []
  },
  {
    title: 'Advanced formatting and Positioning',
    description: 'Learn about more advanced techniques to format elements with backgrounds, gradients, and a new technique for positioning: the Flexbox model.',
    posts: []
  },
  {
    title: 'Flexbox and CSS Grid Layouts',
    description: 'Flexbox is just one way of positioning elements. CSS Grid is another. Learn to use these advanced positioning techniques that are universal and work across several screen sizes.',
    posts: []
  },
  {
    title: 'Cross platform and Responsive Design with CSS',
    description: 'In a world of multiple screen sizes, a compelling interface must adapt to the amount of space available. Learn about advanced techniques to create this adaptability with media queries and CSS Grid patterns.',
    posts: []
  },
  {
    title: 'Styling projects with CSS',
    description: 'Explore practical styles in collections of projects that use them. Build and style a sudoku puzzle, a periodic table cell, and a digital library.',
    posts: []
  },
  {
    title: 'Advanced CSS and State of the Art',
    description: 'Learn about CSS frameworks and their purpose. Explore different techniques for applying CSS frameworks in creating your own customized design.',
    posts: []
  },
];
