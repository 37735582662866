import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, media } from '../tokens';

export const CurriculumPageContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  @media ${media.medium} {
    padding: 2rem;
  }
`
export const SubjectTitle = styled.h1`
  margin-bottom: 10px;
  font-size: 2rem;
`
export const ChapterContainer = styled.section`
  padding-left: 0.5rem;
  padding-bottom: 0.7rem;
  padding-top: 0.8rem;

  @media ${media.medium} {
    padding-left: 2rem;
  }
`
export const ChapterTitle = styled.h2`
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
`
export const ChapterDescription = styled.p`
  font-weight: bolder;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
`
export const SectionLink = styled(Link)`
  color: ${colors.primary};
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: ${colors.secondary}
  }
`