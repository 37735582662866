import React, { Component } from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/layout';
import cssCurriculum from '../../curriculum/css';
import { 
  CurriculumPageContainer, SubjectTitle,
  ChapterContainer, ChapterTitle, ChapterDescription, SectionLink
} from '../../Views/components';

class CSSPage extends Component {
  render() { 
    return (
      <Layout>
        <SEO
          title="CSS - learn to make beautiful websites by writing code"
          description="CSS Tutorial by Nowispow. Learn to make interactive websites and applications more stunning by writing custom CSS code. Whether you are a student or wanting to start a career in the tech field, these tutorials will help you."
        />
        <CurriculumPageContainer>
          <SubjectTitle>Beautify your web pages with CSS</SubjectTitle>
          <p>Note: This page is still in progress. It may break unexpectedly. Please be patient as new content is released.</p>
          <hr />
            {cssCurriculum.map(
              (section, index) => (
                <ChapterContainer key={index + '-' + section && section.title}>
                  <ChapterTitle>{section && section.title}</ChapterTitle>
                  <ChapterDescription>{section && section.description}</ChapterDescription>
                  {section && section.posts && section.posts.length ? 
                    section.posts.map(
                      (eachPost, postIdx) => (
                        <div key={postIdx}>
                          <br />
                          <span>{eachPost && eachPost.subsection} - </span><SectionLink to={'../' + (eachPost && eachPost.slug)}>{eachPost && eachPost.title}</SectionLink>
                        </div>
                      )
                    ) :
                    null
                  }
                </ChapterContainer>
              )
            )}
        </CurriculumPageContainer>
      </Layout>
    );
  }
}
 
export default CSSPage;